@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type:none;
}

a{
  color: #333;
  text-decoration: none;
 }

 body{
  font-size:1.2rem;
  line-height: 1.3;
  font-family: 'Poppins', sans-serif;

 }

 @layer base {
  button{
    @apply border py-2 px-3 rounded-full
  }

}
