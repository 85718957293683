.section_padding{
    padding:0rem 4rem;
}

.sb_footer-links_div a{
    text-decoration: none;
    color: black;
}

.footer{
    background-color: #0288D1;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;


}

.clear{
 margin-top: 0.8rem;
}

.sb_footer{
    display:flex;
    flex-direction: column;

}

.sb_footer-links{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
    text-align: left;
    margin-bottom: rem;
}

.sb_footer-links_div{
    width:190px;
    margin: 1rem;
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
}

a{
    color: rgb(175,175, 179);
    text-decoration: none;
}

.socialmedia{

    display: flex;
    flex-direction: row;

}

.socialmedia img{
    width: 100%;
}

.sb_footer-links_div h4{
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 0.2rem;
}

.sb_footer-links_div p{
    font-size: 12px;
    line-height: 15px;
    margin: 0.5rem 0.5rem;
    cursor: pointer;
}

.sb_footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb_footer-below-links{
    display: flex;
    flex-direction: row;
}

.sb_footer-below-links p{
    font-size: 12px;
    line-height: 15px;
    margin-left: 2rem;
    color: white;
}

h4{
    color: white !important;
    width: 100%;
}

.sb_footer-copyright p{
    font-size: 12px;
    line-height: 15px;
    color: rgb(255, 255,255);
    font-weight: 600;
}

@media screen and (max-width: 850px) {
    .sb_footer-heading h1{
        font-size: 44px;
        line-height: 50px;

    }

}

@media screen and (max-width:550px) {
    .sb_footer-heading h1{
        font-size: 34px;
        line-height:42px;
    }

    .sb_footer-links div{
        margin: 1rem 0;
    }

    .sb_footer-btn p{
        font-size:14px ;
        line-height: 20px;
    }

    .sb_footer-below{
        flex-direction: column;
        justify-content: left;
    }

    .sb_footer-below-links{
        margin-left: 0rem;
        margin-top: 1 rem;
    }
}

@media screen and (max-idth: 400px) {
    .sb_footer-heading h1{
        font-size: 27px;
        line-height: 38px;

    }
}

