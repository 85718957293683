.hero {
    background: url('../../assets/Heropic.png') no-repeat center/cover;
    display:flex;
    width: 100%;
    height: 100vh;


}



