.find{
    max-width: 1040px;
    margin: auto;
}

.heading{
    font-size: 4rem;
    text-align: center;
    padding:2rem;

}

.heading p{
    text-align: center;
}

.text_bg{
    background: #4B9CD3;
    height: 30px;
    max-width: 650px;
    margin:auto;
    position:absolute;
    left: 0;
    right: 0;
}

.text_bg p{
    font-size: 1.4rem;
    margin-top: -.6rem;
}

.slider_container{
    padding:5rem 0;
}

.card{
    background-color: aliceblue;
    margin: .5rem;
    border-radius: 10px;
    box-shadow:rgba(99, 99, 99, 0.2)0px 2px 8px 0px ;
}

.card img{
    padding-top: 0.9rem;
    width: 100%;
    height: 200px;
    object-fit: contain;

}
