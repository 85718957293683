.navbar{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:1rem;
}



.menu{
    display:flex;
    align-items:center;
}

.menu li {
    padding:1rem;

}

.navbar a{
    font-size: .8rem;
    font-weight: 2rem;
    color: black;
}


 .mobile_btn{
    display: none;
 }

 @media screen and (max-width:720px){
    .mobile_btn{
        display: block;
        position:absolute;
        right:1rem;
        cursor: pointer;
        z-index: 10;

    }

    .menu{
        display: flex;
        flex-direction: column;
        justify-content:center ;
        position: fixed;
        left: -100%;
        right: 0;
        bottom: 0;
        top: 0;
        width:100%;
        height:100vh;

        background-color: #f8f8f8;
        z-index: 10;
        transition: left 1s;

    }

    .active{
        left: 0;
    }

    .navbar a {
        font-size: 2rem;
    }
 }
